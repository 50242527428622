export interface BillingFile {
  id?: number;
  billing_id?: number;
  sort_no?: number;
  path: string;
  name: string;
  comment: string;
}

export const DEFAULT_BILLING_FILE = {
  id: 1,
  billing_id: 1,
  sort_no: undefined,
  path: "",
  name: "",
  comment: "",
};

export interface Billing {
  id: number;
  agreement_id: number;
  office_id: number;
  start_date: string;
  end_date: string;
  use_receipt_type: number;
  use_receipt_comment: string;
  account_notice_date: string;
  trial_start_date: string;
  trial_end_date: string;
  stop_date: string;
  stop_reason: number;
  stop_memo: string;
  pause_end: string;
  agreement_plan_type: number;
  agreement_plan_name: string;
  estimate_no: string;
  ewell_sales_staff_fullname: string;
  ewell_sales_staff_mail: string;
  customer_support_mail: string;
  first_login_id: string;
  first_login_password: string;
  group_office_code: string;
  post_code: string;
  pref: number;
  city: string;
  area: string;
  building: string;
  billing_name: string;
  is_send_receipt_system: number;
  bank_selection: number;
  financial_code: string;
  branch_code: string;
  bank_name: string;
  bank_type: number;
  branch_name: string;
  branch_type: number;
  deposit_type: number;
  account_number: string;
  account_furigana: string;
  account_name: string;
  japan_post_bank_code: string;
  japan_post_bank_number: string;
  aplus_code: string;
  is_attendance_use: number;
  is_chiken_use: number;
  ibow_receipt_div: number;
  receipt_agreement_start_date: string;
  receipt_agreement_end_date: string;
  receipt_start_date: string;
  receipt_end_date: string;
  receipt_is_send_receipt_system: number;
  receipt_notice_mailaddress: string;
  is_ocr_enabled: number;
  ocr_agreement_start_date: string;
  ocr_agreement_end_date: string;
  ocr_start_date: string;
  ocr_end_date: string;
  remarks: string;
  start_date_wareki: string;
  end_date_wareki: string;
  account_notice_date_wareki: string;
  trial_start_date_wareki: string;
  trial_end_date_wareki: string;
  stop_date_wareki: string;
  pause_end_wareki: string;
  updated_at: string;
  updated_at_wareki: string;
  billing_files: BillingFile[];
  confflag: number;
  billing_comments: BillingComment[];
  change_message: string;
  plan_storage: number;

  /** OJT研修機能 */
  use_ojt: number;
  /** OJT研修契約開始日 */
  ojt_agreement_start_date: string;
  /** OJT研修契約終了日 */
  ojt_agreement_end_date: string;
  /** OJT研修開始日 */
  ojt_start_date: string;
  /** OJT研修終了日 */
  ojt_end_date: string;
  /** OJT研修通知メール */
  ojt_notice_mailaddress: string;

  /** レセプト代行使用フラグ */
  receipt_agency_div: string;

  /** レセプト事前チェック機能 0:未使用 1:使用 */
  use_receipt_pre_check: number;

  is_bi_enabled: number;

  /** iBowAI自動作成 看護計画書AI自動作成機能 0:OFF 1:ON */
  is_ai_enabled: number;
  /** iBowAI自動作成 月次報告書AI自動作成機能 0:OFF 1:ON */
  is_ai_month_report_enabled: number;
  /** iBowAI自動作成 月次報告書AI自動作成機能(別添) 0:OFF 1:ON */
  is_ai_month_report_betten_enabled: number;
  /** iBowAI自動作成 開始日 */
  ai_report_start_date: string;
  /** iBowAI自動作成 終了日 */
  ai_report_end_date: string;
  /** iBowAI自動作成 契約開始日 */
  ai_report_agreement_start_date: string;
  /** iBowAI自動作成 契約終了日 */
  ai_report_agreement_end_date: string;
  /** iBowAI自動作成 課金開始日 */
  ai_report_billing_start_date: string;
  /** iBowAI自動作成 課金終了日 */
  ai_report_billing_end_date: string;
  /** iBowAI自動作成 請求管理システム送信フラグ 0:送る 1:送らない */
  ai_report_is_send_receipt_system: number;
  /** iBowAI自動作成 通知メールアドレス */
  ai_report_notice_mailaddress: string;
}

export const DEFAULT_BILLING: Billing = {
  id: 1,
  agreement_id: 1,
  office_id: 1,
  start_date: "",
  end_date: "",
  use_receipt_type: 1,
  use_receipt_comment: "",
  account_notice_date: "",
  trial_start_date: "",
  trial_end_date: "",
  stop_date: "",
  stop_reason: 1,
  stop_memo: "",
  pause_end: "",
  agreement_plan_type: 1,
  agreement_plan_name: "",
  estimate_no: "",
  ewell_sales_staff_fullname: "",
  ewell_sales_staff_mail: "",
  customer_support_mail: "",
  first_login_id: "",
  first_login_password: "",
  group_office_code: "",
  post_code: "",
  pref: 1,
  city: "",
  area: "",
  building: "",
  billing_name: "",
  is_send_receipt_system: 1,
  bank_selection: 1,
  financial_code: "",
  branch_code: "",
  bank_name: "",
  bank_type: 1,
  branch_name: "",
  branch_type: 1,
  deposit_type: 1,
  account_number: "",
  account_furigana: "",
  account_name: "",
  japan_post_bank_code: "",
  japan_post_bank_number: "",
  aplus_code: "",
  is_attendance_use: 0,
  is_chiken_use: 0,
  ibow_receipt_div: 1,
  receipt_agreement_start_date: "",
  receipt_agreement_end_date: "",
  receipt_start_date: "",
  receipt_end_date: "",
  receipt_is_send_receipt_system: 1,
  receipt_notice_mailaddress: "",
  is_ocr_enabled: 0,
  ocr_agreement_start_date: "",
  ocr_agreement_end_date: "",
  ocr_start_date: "",
  ocr_end_date: "",
  remarks: "",
  start_date_wareki: "",
  end_date_wareki: "",
  account_notice_date_wareki: "",
  trial_start_date_wareki: "",
  trial_end_date_wareki: "",
  stop_date_wareki: "",
  pause_end_wareki: "",
  updated_at: "",
  updated_at_wareki: "",
  billing_files: [] as BillingFile[],
  confflag: 1,
  billing_comments: [] as BillingComment[],
  change_message: "",
  plan_storage: 0,
  use_ojt: 0,
  ojt_agreement_start_date: "",
  ojt_agreement_end_date: "",
  ojt_start_date: "",
  ojt_end_date: "",
  ojt_notice_mailaddress: "",
  receipt_agency_div: "",
  is_bi_enabled: 0,
  use_receipt_pre_check: 0,
  is_ai_enabled: 0,
  is_ai_month_report_enabled: 0,
  is_ai_month_report_betten_enabled: 0,
  ai_report_start_date: "",
  ai_report_end_date: "",
  ai_report_agreement_start_date: "",
  ai_report_agreement_end_date: "",
  ai_report_billing_start_date: "",
  ai_report_billing_end_date: "",
  ai_report_is_send_receipt_system: 0,
  ai_report_notice_mailaddress: "",
};

export interface BillingComment {
  id: number;
  billing_id: number;
  confflag: number;
  comment: string;
}

export const DEFAULT_BILLING_COMMENT: BillingComment = {
  id: 0,
  billing_id: 0,
  confflag: -2,
  comment: "",
};
