



















































import { Component, Mixins } from "vue-property-decorator";
import SearchBase from "@/components/admin_common/SearchBase.vue";
import { Inquiry } from "@/model/inquiry";
import AxiosMixin from "@/mixins/axiosMixin";
import InquiryList from "@/components/inquiry/InquiryList.vue";
import { Choice } from "@/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import InquirySearch from "@/components/inquiry/InquirySearch.vue";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UserMixin from "@/mixins/userMixin";
import {
  InquirySearchObj,
  DEFAULT_INQUIRY_SEARCH_OBJ,
} from "@/views/inquiry/types";
import { COLLECTION_INQUIRY_SEARCH_OBJ } from "@/consts/envFireStore";

/** 表示用Inquiry */
interface DispInquiry extends Inquiry {
  ticket_no: string;
  last_answered_datetime: string;
  co_code: string;
  kst_no: string;
  corresponding_admin_names: string;
  use_receipt_type: number;
  inquiry_date: string;
  inquiry_time: string;
  all_answer_contents: string;
  answer_statuses: number;
  need_mark_hope_contact_date_time: number;
  inquiry_staff_name: string;
  inquiry_staff_furigana: string;
  co_name: string;
  office_name: string;
  questionnaire_text: number;
  questionnaire_color: string;
}

@Component({
  components: {
    SearchBase,
    InquiryList,
    AppAuthButton,
    InquirySearch,
  },
})
export default class InquiryCompleted extends Mixins(
  AxiosMixin,
  FireStoreMixin,
  UserMixin
) {
  private inquirys = [] as DispInquiry[];

  /** 合計ページ件数 */
  private pageCount = 0;
  /** ページング表示件数 */
  private dispPageLimit = 7;

  /** 現在のページ番号 */
  private pageNo = 1;
  /** 1ページあたりの表示件数 */
  private perPage = 10;

  /** 1ページあたりの表示件数一覧 */
  private pageLimits: Choice[] = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
  ];

  /** 検索条件 */
  private searchObj: InquirySearchObj = { ...DEFAULT_INQUIRY_SEARCH_OBJ };

  /** ヘッダー情報 */
  private headers = [
    {
      text: "アンケート回答結果",
      align: "center",
      sortable: false,
      value: "questionnaire_text",
      width: "150px",
    },
    {
      text: "詳細",
      align: "center",
      sortable: false,
      value: "actions",
      width: "50px",
    },
    {
      text: "バージョン",
      sortable: false,
      value: "version",
      width: "100px",
    },
    {
      text: "希望連絡方法(チケット番号)",
      align: "center",
      sortable: false,
      value: "hope",
      width: "190px",
    },
    {
      text: "事業所名",
      align: "start",
      sortable: true,
      value: "office_name",
      width: "180px",
    },
    {
      text: "対応者",
      align: "start",
      sortable: false,
      value: "admin_name",
      width: "150px",
    },
    {
      text: "サービス種類",
      align: "start",
      sortable: false,
      value: "services",
      width: "180px",
    },
    {
      text: "問い合わせ種類",
      align: "start",
      sortable: false,
      value: "divs",
      width: "200px",
    },
    {
      text: "問い合わせ日",
      align: "start",
      sortable: true,
      value: "inquiry_date",
      width: "150px",
    },
    {
      text: "問い合わせ時刻",
      align: "start",
      sortable: true,
      value: "inquiry_time",
      width: "150px",
    },
    {
      text: "最終回答日時",
      align: "start",
      sortable: false,
      value: "last_answered_datetime",
      width: "150px",
    },
    {
      text: "企業名",
      align: "start",
      sortable: true,
      value: "co_name",
      width: "150px",
    },
  ];

  public async created() {
    this.collection = COLLECTION_INQUIRY_SEARCH_OBJ;
    this.documentId = String(this.User.id);
    const data = await this.documentGet();
    if (data) {
      this.searchObj = data as InquirySearchObj;
    }
  }

  /** 検索条件のリセット時 */
  private resetSearchObj() {
    this.searchObj = { ...DEFAULT_INQUIRY_SEARCH_OBJ };
  }

  // APIを呼んで完了状態の問い合わせ情報をLimit、Offset付きで取得
  private fetchInquirys(): void {
    const offset = (this.pageNo - 1) * this.perPage;

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiries/get",
      {
        ...this.searchObj,
        use_type: 2,
        limit: this.perPage,
        offset: offset,
      },
      (res) => {
        this.inquirys = res.data.inquirys;

        // 合計ページ数の再計算
        this.pageCount = Math.ceil(res.data.inquiry_count / this.perPage);

        this.scrollTop();
      }
    );
  }

  /** 詳細画面へ */
  private gotoEditPage(id: number) {
    window.open("/inquiry/show/" + id, "_blank");
  }

  /** 検索 */
  private search() {
    this.pageNo = 1;
    this.documentSave(this.searchObj);
    this.fetchInquirys();
  }

  /** ページ変更 */
  private changePage() {
    this.fetchInquirys();
  }

  // 表示件数変更
  private changePerPage() {
    this.pageNo = 1;
    this.fetchInquirys();
  }

  private scrollTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }

  //並び順序変更
  private updateSortBy(value: []) {
    this.searchObj.sortBy = value;
    this.documentSave(this.searchObj);
  }

  //並び項目変更
  private updateSortDesc(value: []) {
    this.searchObj.sortDesc = value;
    this.documentSave(this.searchObj);
  }
}
