import Vue from "vue";
import Component from "vue-class-component";
import { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

@Component
export default class AxiosMixin extends Vue {
  timeout = 2000;
  loading = 0;

  public postJson(
    url: string,
    param: unknown,
    sucessFunc: (res: AxiosResponse) => void,
    option: AxiosRequestConfig,
    blobRes: boolean
  ): void {
    this.loading++;
    option.headers = {
      ...option.headers,
      Authorization: "Bearer " + window.token,
    };
    window.axios
      .post(url, param, option)
      .then((response: AxiosResponse) => {
        this.loading--;
        sucessFunc(response);
      })
      .catch(async (error: AxiosError) => {
        if (error.response && error.response.status == 401) {
          this.refToken(url, param, blobRes, sucessFunc);
        } else {
          this.loading--;
          console.log(error);
          if (error.response && option.responseType == "blob") {
            const data = JSON.parse(await error.response.data.text());
            error.response.data = data;
          }
          this.showErrorMsg(error);
        }
      });
  }

  public postJsonCheck(
    url: string,
    param: unknown,
    sucessFunc: (res: AxiosResponse) => void
  ): void {
    const func = async (response: AxiosResponse) => {
      if (response.data.code > 0) {
        await this.$openAlert(response.data.message);
      } else {
        sucessFunc(response);
      }
    };
    this.postJson(url, param, func, {}, false);
  }

  public postJsonBlobResCheck(
    url: string,
    param: unknown,
    sucessFunc: (res: AxiosResponse) => void
  ): void {
    const func = async (response: AxiosResponse) => {
      if (response.data.code && response.data.code > 0) {
        await this.$openAlert(response.data.message);
      } else {
        sucessFunc(response);
      }
    };
    this.postJson(url, param, func, { responseType: "blob" }, true);
  }

  public postJsonBackground(
    url: string,
    param: object,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    }
  ): void {
    this.postJson(
      url,
      param,
      sucessFunc,
      {},
      false //エラー時にアラートを表示しない
    );
  }

  private async showErrorMsg(error: AxiosError): Promise<void> {
    let msg = "サーバでエラーが発生しました。";
    if (error.response) {
      switch (error.response.status) {
        case 429: // Too Many Requests
          msg =
            "サーバーが大変混み合っています。申し訳ありませんが、しばらくたってからやり直してください。";
          break;
      }
      if (error.response.data.code == 101) {
        //業務エラーの場合
        msg = error.response.data.message;
      }
    }
    await this.$openAlert(msg);
  }

  private refToken(
    url: string,
    param: unknown,
    blobRes: boolean,
    successFunc: (res: AxiosResponse) => void
  ) {
    window.axios
      .post(window.base_url + "/auth/refresh", {
        ref_token: window.reftoken,
      })
      .then((response: AxiosResponse) => {
        this.loading--;
        const base64Url = response.data.token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        const payload = JSON.parse(jsonPayload);
        window.token = response.data.token;
        Cookies.set("admin-token", response.data.token);
        window.reftoken = response.data.ref_token;
        Cookies.set("admin-reftoken", response.data.ref_token);
        window.name = payload.name;
        Cookies.set("admin-name", payload.name);
        if (blobRes) {
          this.postJsonBlobResCheck(url, param, successFunc);
        } else {
          this.postJsonCheck(url, param, successFunc);
        }
      })
      .catch(async (error: AxiosError) => {
        this.loading--;
        if (error.response && error.response.status == 401) {
          await this.$openAlert("セッションがタイムアウトになりました。");
          window.token = "";
          Cookies.set("admin-token", "");
          window.reftoken = "";
          Cookies.set("admin-reftoken", "");
          window.name = "";
          Cookies.set("admin-name", "");
          location.href = "/";
        } else {
          this.showErrorMsg(error);
        }
      });
  }
}
