var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ibow2-input-field",style:({
    width: _vm.$vuetify.breakpoint.xs ? '100%' : _vm.width,
    'min-width': _vm.minWidth
  })},[_c('v-select',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","hide-details":"auto","label":_vm.Label,"items":_vm.items,"multiple":_vm.multiple,"suffix":_vm.suffix,"height":_vm.height,"rules":_vm.SaveCheckRules,"validate-on-blur":true,"menu-props":_vm.menuProps},scopedSlots:_vm._u([(_vm.$scopedSlots.item)?{key:"item",fn:function(payload){return [_vm._t("item",null,null,payload)]}}:{key:"item",fn:function(ref){
  var item = ref.item;
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"id":_vm.getId(item)}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getText(item)))])],1)]}},(_vm.multiple)?{key:"selection",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [(index === 0)?[('chips' in _vm.$attrs)?_c('v-chip',[_c('span',[_vm._v(_vm._s(_vm.getText(item)))])]):_c('span',[_vm._v(_vm._s(_vm.getText(item)))])]:_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("（他 "+_vm._s(_vm.value.length - 1)+" 項目）")]):_vm._e()]}}:null,{key:"append",fn:function(){return [_c('AppIcon',{attrs:{"icon":"chrvron-down","fill":_vm.$vuetify.theme.themes.light.grey,"width":"12"}})]},proxy:true}],null,true),model:{value:(_vm.Value),callback:function ($$v) {_vm.Value=$$v},expression:"Value"}},'v-select',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }