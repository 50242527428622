import Vue from "vue";
import Component from "vue-class-component";
import { firebase, db } from "@/firebase";

@Component
export default class FireStoreMixin extends Vue {
  public collection = ""; // コレクション名
  public documentId = ""; // ドキュメントID

  public async documentGet(): Promise<
    firebase.firestore.DocumentData | undefined
  > {
    const snapshot = await db
      .collection(this.collection)
      .doc(this.documentId)
      .get();
    if (!snapshot.exists) {
      return undefined;
    }
    return snapshot.data();
  }

  public getDocument() {
    return db.collection(this.collection).doc(this.documentId);
  }

  public documentSave(document: firebase.firestore.DocumentData): void {
    db.collection(this.collection).doc(this.documentId).set(document).then();
  }

  public documentDelete(): void {
    db.collection(this.collection).doc(this.documentId).delete().then();
  }
}
