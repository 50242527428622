






















































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { VForm, Choice } from "@/types";
import * as appDate from "#/utility/appDate";
import { ListIndex } from "@/views/aivnplan/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { COLLECTION_AI_INDEX } from "@/consts/envFireStore";

interface BatchSetting {
  /** データセットID */
  dataset_id: number;
  /** ON/OFFフラグ */
  enable: boolean;
  /** 最大処理件数 */
  process_limit: number;
}

@Component({
  components: {
    AppAuthButton,
  },
})
/**
 * 計画書自動作成 インデックス設定
 */
export default class IndexConfigure extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin,
  FireStoreMixin
) {
  /** Ref */

  /** バッチ処理設定フォーム操作用 */
  @Ref("batch-form") private readonly batchForm!: VForm;

  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** データセットID */
  private datasetId = 0;

  /** 指定年月 */
  private yearmonth = appDate.thisMonth();

  /** 最大処理件数 */
  private processLimit = 500;

  /** データセット選択肢 */
  private datasetChoice: Choice[] = [];

  /** フィルター用キーワード */
  private searchKeyword = "";

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** 表の現在のページ数 */
  private pageNo = 1;

  /** 作成済みインデックス情報 */
  private indices: ListIndex[] = [];

  /** データテーブル用ヘッダー */
  private headers = [
    {
      text: "データセット名",
      align: "start",
      value: "dataset_name",
      filterable: true,
      sortable: false,
    },
    {
      text: "対象年月",
      align: "start",
      value: "yearmonth_str",
      filterable: true,
      sortable: true,
    },
    {
      text: "処理件数",
      align: "start",
      value: "processed_count",
      filterable: false,
      sortable: true,
    },
    {
      text: "作成日時",
      align: "start",
      value: "created_at_str",
      filterable: false,
      sortable: true,
    },
    {
      text: "有効",
      align: "start",
      value: "valid_str",
      filterable: false,
      sortable: true,
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "end",
    },
  ];

  /** バッチ処理設定 */
  private batchSetting: BatchSetting = {
    dataset_id: 0,
    enable: false,
    process_limit: 500,
  };

  /** 関数 */

  async created() {
    this.fetch();

    this.collection = COLLECTION_AI_INDEX;
    this.documentId = "admin";
    const data = await this.documentGet();
    if (data) {
      this.batchSetting = data as BatchSetting;
    } else {
      this.batchSetting.dataset_id = this.datasetChoice[0].value;
    }
  }

  /** 取得 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/index/form/get",
      {},
      async (res) => {
        this.indices = res.data.indices;
        this.datasetChoice = res.data.dataset_choice;

        // 先頭の選択肢を選択した状態にしておく
        if (this.datasetChoice && this.datasetChoice.length > 0) {
          this.datasetId = this.datasetChoice[0].value;
        }
      }
    );
  }

  /** インデックス作成 */
  private async createIndex() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("インデックスを作成しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/index/create",
      {
        dataset_id: this.datasetId,
        yearmonth: this.yearmonth,
        process_limit: this.processLimit,
      },
      async () => {
        await this.$openAlert("作成を開始しました。");
        this.fetch();
      }
    );
  }

  /** インデックス削除 */
  private async deleteIndex(indexId: number) {
    if (!(await this.$openConfirm("インデックスを削除しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/index/delete",
      { id: indexId },
      async () => {
        await this.$openAlert("削除しました。");
        this.fetch();
      }
    );
  }

  /** インデックス有効/無効切り替え */
  private switchIndexValid(index: ListIndex) {
    this.postJsonCheck(
      window.base_url + "/api/admin/aivnplan/index/valid/switch",
      { index: index },
      async () => {
        await this.$openAlert("有効/無効が切り替わりました。");
        this.fetch();
      }
    );
  }

  /** 表のページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** バッチ処理設定（データセット）バリデーション */
  private checkBatchSettingDataset(): boolean | string {
    if (!this.batchSetting.enable) {
      return true;
    }

    if (this.batchSetting.dataset_id == 0) {
      return "データセットを選択する必要があります。";
    }

    return true;
  }

  /** バッチ処理設定（最大処理件数）バリデーション */
  private checkBatchSettingProcessLimit(): boolean | string {
    if (!this.batchSetting.enable) {
      return true;
    }

    if (this.batchSetting.process_limit == 0) {
      return "最大処理件数を1件以上に設定する必要があります。";
    }

    return true;
  }

  /** バッチ処理設定保存 */
  private async saveBatchSetting() {
    if (!this.batchForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    this.documentSave(this.batchSetting);
    this.$openAlert("設定を保存しました。");
  }
}
