import Base from "../base";

/** 月次報告書AI自動作成設定 */
export interface AiMonthReportSetting extends Base {
  /** ID */
  id: number;
  /** プロンプトテンプレートID */
  prompt_template_id: number;
  /** プロンプトテンプレートID(別添自動作成) */
  betten_prompt_template_id: number;
  /** 1:作成オプションに「自動作成に別添も含む」を追加 */
  add_betten_option: number;
  /** 作成オプション「自動作成に別添も含む」デフォルト値 */
  default_betten_option: number;
  /**  1:作成オプションに「日常生活自立度」を追加 */
  add_daily_life_rate_div_option: number;
  /**  作成オプション「日常生活自立度」デフォルト値 */
  default_daily_life_rate_div_option: number;
  /**  1:作成オプションに「認知症高齢者の日常生活自立度」を追加 */
  add_dementia_daily_life_rate_div_option: number;
  /**  作成オプション「認知症高齢者の日常生活自立度」デフォルト値 */
  default_dementia_daily_life_rate_div_option: number;
  /**  1:作成オプションに「理学療法士、作業療法士又は言語聴覚士が行った訪問看護、家族等への指導、リスク管理等の内容」を追加 */
  add_contents_option: number;
  /**  作成オプション「理学療法士、作業療法士又は言語聴覚士が行った訪問看護、家族等への指導、リスク管理等の内容」デフォルト値 */
  default_contents_option: number;
  /**  1:作成オプションに「ADL評価」を追加 */
  add_adl_option: number;
  /**  作成オプション「ADL評価」デフォルト値 */
  default_adl_option: number;
  /**  1:作成オプションに「コミュニケーション」を追加 */
  add_communication_option: number;
  /**  作成オプション「コミュニケーション」デフォルト値 */
  default_communication_option: number;
  /**  1:作成オプションに「家庭内の役割」を追加 */
  add_role_in_the_home_option: number;
  /**  作成オプション「家庭内の役割」デフォルト値 */
  default_role_in_the_home_option: number;
  /**  1:作成オプションに「余暇活動」を追加 */
  add_leisure_activities_option: number;
  /**  作成オプション「余暇活動」デフォルト値 */
  default_leisure_activities_option: number;
  /**  1:作成オプションに「社会地域活動」を追加 */
  add_volunteer_activities_option: number;
  /**  作成オプション「社会地域活動」デフォルト値 */
  default_volunteer_activities_option: number;
  /**  1:作成オプションに「終了後に行いたい社会参加等の取組」を追加 */
  add_activities_after_option: number;
  /**  作成オプション「終了後に行いたい社会参加等の取組」デフォルト値 */
  default_activities_after_option: number;
  /** 1:作成オプションに「本日の状態（バイタル）」を追加 */
  add_vital_option: number;
  /** 作成オプション「本日の状態（バイタル）」デフォルト値 */
  default_vital_option: number;
  /** 1:作成オプションに「観察項目」を追加 */
  add_observation_option: number;
  /** 作成オプション「観察項目」デフォルト値 */
  default_observation_option: number;
  /** 1:作成オプションに「最新計画月の看護計画」を追加 */
  add_vn_plan_option: number;
  /** 作成オプション「最新計画月の看護計画」デフォルト値 */
  default_vn_plan_option: number;
  /** 1:作成オプションに「最新報告月の文章スタイル反映」を追加 */
  add_pre_month_report_option: number;
  /** 作成オプション「最新報告月の文章スタイル反映」デフォルト値 */
  default_pre_month_report_option: number;
  /** 最終更新者ID */
  editor_admin_id: number;
}

export const DefaultAiMonthReportSetting = (): AiMonthReportSetting => ({
  id: 0,
  prompt_template_id: 0,
  betten_prompt_template_id: 0,
  add_betten_option: 0,
  default_betten_option: 0,
  add_daily_life_rate_div_option: 0,
  default_daily_life_rate_div_option: 0,
  add_dementia_daily_life_rate_div_option: 0,
  default_dementia_daily_life_rate_div_option: 0,
  add_contents_option: 0,
  default_contents_option: 0,
  add_adl_option: 0,
  default_adl_option: 0,
  add_communication_option: 0,
  default_communication_option: 0,
  add_role_in_the_home_option: 0,
  default_role_in_the_home_option: 0,
  add_leisure_activities_option: 0,
  default_leisure_activities_option: 0,
  add_volunteer_activities_option: 0,
  default_volunteer_activities_option: 0,
  add_activities_after_option: 0,
  default_activities_after_option: 0,
  add_vital_option: 0,
  default_vital_option: 0,
  add_observation_option: 0,
  default_observation_option: 0,
  add_vn_plan_option: 0,
  default_vn_plan_option: 0,
  add_pre_month_report_option: 0,
  default_pre_month_report_option: 0,
  editor_admin_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});
