import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const devFirebaseConfig = {
  apiKey: "AIzaSyAyCgDV9LJYsfGJ17PAk2oCfyEG1VIM77w",
  authDomain: "ibow2-302300.firebaseapp.com",
  projectId: "ibow2-302300",
  storageBucket: "ibow2-302300.appspot.com",
  messagingSenderId: "1084741809460",
  appId: "1:1084741809460:web:fa9afbcc33dc32eed6a161",
};

const addFirebaseConfig = {
  apiKey: "AIzaSyDBMIld6VICu-YY1XC2J_MZ7Xr7Ss8FUH4",
  authDomain: "ibowv2-add-dev.firebaseapp.com",
  projectId: "ibowv2-add-dev",
  storageBucket: "ibowv2-add-dev.appspot.com",
  messagingSenderId: "552309459287",
  appId: "1:552309459287:web:a72f0555db6ff2ff249612"
};
  
const stgFirebaseConfig = {
  apiKey: "AIzaSyDXPeoyktFtqu5QieMKeoR-lS1-7_uKW18",
  authDomain: "ibowv2-trial.firebaseapp.com",
  projectId: "ibowv2-trial",
  storageBucket: "ibowv2-trial.appspot.com",
  messagingSenderId: "482646732140",
  appId: "1:482646732140:web:cd75296d711d4f49bb1749",
};

const prdFirebaseConfig = {
  apiKey: "AIzaSyBWkh69I2mNXC1ZLIpr0QeewDTuJZoBjc0",
  authDomain: "ibowv2.firebaseapp.com",
  projectId: "ibowv2",
  storageBucket: "ibowv2.appspot.com",
  messagingSenderId: "852337431318",
  appId: "1:852337431318:web:c53e0876b2768b2847b637",
};

const prefirebaseConfig = {
  apiKey: "AIzaSyDo46htLtJDosD2xkoKPHvuyNazF1yUFLQ",
  authDomain: "ibowv2pre.firebaseapp.com",
  projectId: "ibowv2pre",
  storageBucket: "ibowv2pre.appspot.com",
  messagingSenderId: "366656082352",
  appId: "1:366656082352:web:643297118ceb3a2ba04675",
};

let firebaseConfig = devFirebaseConfig;
switch (process.env.VUE_APP_MODE) {
  case "dev2":
    firebaseConfig = addFirebaseConfig;
    break;
  case "add":
    firebaseConfig = addFirebaseConfig;
    break;
  case "stg":
    firebaseConfig = stgFirebaseConfig;
    break;
  case "prd":
    firebaseConfig = prdFirebaseConfig;
    break;
  case "pre":
    firebaseConfig = prefirebaseConfig;
    break;
}

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const storage = firebase.storage();

export { firebase, db, storage };
