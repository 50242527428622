type SelectItemKey<T = Record<string, any>> =
  | boolean
  | null
  | undefined // Ignored
  | string // Lookup by key, can use dot notation for nested objects
  | readonly (string | number)[] // Nested lookup by key, each array item is a key in the next level
  | ((item: T, fallback?: any) => any);

function getNestedValue(
  obj: any,
  path: (string | number)[],
  fallback?: any
): any {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

function getObjectValueByPath(
  obj: any,
  path?: string | null,
  fallback?: any
): any {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== "string") return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  return getNestedValue(obj, path.split("."), fallback);
}

export function getPropertyFromItem(
  item: any,
  property: SelectItemKey,
  fallback?: any
): any {
  if (property === true) return item === undefined ? fallback : item;

  if (property == null || typeof property === "boolean") return fallback;

  if (item !== Object(item)) {
    if (typeof property !== "function") return fallback;

    const value = property(item, fallback);

    return typeof value === "undefined" ? fallback : value;
  }

  if (typeof property === "string")
    return getObjectValueByPath(item, property, fallback);

  if (Array.isArray(property)) return getNestedValue(item, property, fallback);

  if (typeof property !== "function") return fallback;

  const value = property(item, fallback);

  return typeof value === "undefined" ? fallback : value;
}
